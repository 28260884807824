import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Grid,
  makeStyles,
  Box,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import classNames from "classnames";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AppPagination from "_components/AppPagination";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PolicyServices from "../../../../../PolicyExplorer/services";
import { alertActions, loadingActions } from "_actions";
import ChatIcon from "@mui/icons-material/Chat";
import DocumentSplitter from "containers/manage-regulation/Policy/PolicyExplorer/component/PolicyDataTable/ChatBot/documentSplitter";
import ChatBox from "_components/ChatBot/ChatBox";
import CircleIcon from "@mui/icons-material/Circle";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { downloadFile } from "_helpers/utils";

const useStyles = makeStyles(() => ({
  BoxPages: {
    padding: "15px 10px",
    boxShadow: "inset rgb(0 0 0 / 17%) 0px 0px 14px 2px;",
    position: "relative",
    overflow: "hidden",
    height: "650px",
  },
  MatchBox: {
    display: "inline-block",
    padding: "0px 6px",
    border: "1px solid #e0e0e0",
    position: "relative",
    top: "0px",
    textAlign: "center",
    marginRight: "3px",
  },
  textRight: {
    textAlign: "right",
  },
  marginTop15px: {
    marginTop: "15px",
  },
  headerFont: {
    fontWeight: "500",
  },
  titalSpace: {
    padding: "0px 0px 5px",
    cursor: "pointer",
    borderBottom: "1px solid #e0e0e0",
  },
  countNum: {
    width: "auto",
    height: "20px",
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    padding: "5px 6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontWeight: "500",
    marginRight: "10px",
    fontSize: "11px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
}));

const FilterSentenceComponent = ({
  sentences,
  vectorsFilter,
  type,
  setComparedSentencesView,
  setSelectedBaseHeader,
  policyFolderUrl,
  policyName,
  selectedBaseHeader,
  setSelectedHeader,
  selectedHeader,
  setMatchedContent,
  policyID,
  chatBotAvailibility,
  setChatBotAvailibility,
  // tokenizedSentences,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const allowedNavItems = useSelector(
    (state) => state.authentication?.userData?.allowedNavItems
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPolicyPageNo, setCurrentPolicyPageNo] = useState(0);

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(sentences));
  const [expandedList, setExpandedList] = useState([]);
  const [comparisonCountByHeader, setComparisonCountByHeader] = useState({});
  const [isChatBoxAvailable, setIsChatBoxAvailable] = useState(false);
  const [documentSplitterPopUp, setDocumentSplitterPopUp] = useState(false);

  const handleChangePage = (event) => {
    setCurrentPolicyPageNo(event);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setCurrentPolicyPageNo(0);
  };

  const updateExpandList = (sentenceID) => {
    if (expandedList.includes(sentenceID)) {
      let tempList = [...expandedList];
      tempList = tempList.filter((item) => item !== sentenceID);
      setExpandedList(tempList);
    } else {
      setExpandedList((prevState) => [...prevState, sentenceID]);
    }
  };

  const expandCollapseAll = (type) => {
    if (type === "expand") {
      setExpandedList(
        sentences.map((sentence) => sentence.POLICY_EXTRACTION_DETAILS_ID)
      );
    } else {
      setExpandedList([]);
    }
  };

  useEffect(() => {
    if (sentences.length > 0 && sentences[0]["NAME"]) {
      (async () => {
        dispatch(loadingActions.start());
        const response = await PolicyServices.getPolicyComparisonByHeader({
          i_POLICYCOMPARISONID: id,
          i_HEADER_VALUE: sentences[0]["NAME"],
          i_TYPE_ID: "H",
        });
        dispatch(loadingActions.end());
        if (response.status === 200) {
          setMatchedContent([...response["#result-set-1"]]);
          let tempAllComparedSentences = [...response["#result-set-1"]].filter(
            (item) => item["TYPE"].trim() === "T" && item["SEMANTIC_SCORE"] > 0
          );
          setComparisonCountByHeader(
            tempAllComparedSentences.reduce((groups, item) => {
              const group = groups[item.BASE_DOCUMENT.trim()] || [];
              group.push(item);
              groups[item.BASE_DOCUMENT.trim()] = group;
              return groups;
            }, {})
          );
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      })();
    }
  }, [sentences]);

  const columnData = [
    {
      key: "NAME",
      label: langConstant.SENTENCE,
      format: function formatData(value) {
        // let bodyContent = tokenizedSentences.find((item) => item.POLICY_EXTRACTION_DETAILS_ID == value['POLICY_EXTRACTION_DETAILS_ID'])
        let bodyContent = [];
        if (value["SENTENCES"]) {
          bodyContent = JSON.parse(value["SENTENCES"]);
        }

        return (
          <>
            {value["ITEM_BODY_CONTENT"] ? (
              <Accordion
                style={{ boxShadow: "none" }}
                expanded={
                  type === "base"
                    ? true
                    : expandedList.includes(
                        value["POLICY_EXTRACTION_DETAILS_ID"]
                      )
                }
                onClick={() =>
                  updateExpandList(value["POLICY_EXTRACTION_DETAILS_ID"])
                }
              >
                <AccordionSummary
                  style={{ padding: "0px", margin: "0px 0px" }}
                  expandIcon={
                    value["ITEM_BODY_CONTENT"] && type !== "base" ? (
                      <ExpandMoreIcon />
                    ) : null
                  }
                  onClick={() => {
                    if (type === "base") {
                      setComparedSentencesView(true);
                      setSelectedBaseHeader({
                        header: value["NAME"],
                        text: "",
                        type: "H",
                      });
                      if (
                        selectedHeader.header != value["NAME"] ||
                        selectedHeader.type != "H"
                      ) {
                        setSelectedHeader({ header: value["NAME"], type: "H" });
                      }
                    }
                  }}
                >
                  <Typography className={classes.headerFont}>
                    {value["NAME"]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {bodyContent.map((content, contentIndex) => {
                    let replaceContent = content;
                    if (vectorsFilter !== "NONE") {
                      let keywordVectorized = value[vectorsFilter].split(",");
                      keywordVectorized.map((item) => {
                        if (item) {
                          const searchText = new RegExp(item.trim(), "ig");
                          replaceContent = replaceContent.replaceAll(
                            searchText,
                            `<span style='color:red'>${item.trim()}</span>`
                          );
                        }
                        return replaceContent;
                      });
                    }
                    return (
                      <Grid key={contentIndex}>
                        {content.trim() && (
                          <Grid
                            style={{
                              borderRadius: "15px",
                              backgroundColor:
                                type === "base" &&
                                selectedBaseHeader.text === content.trim()
                                  ? "#6e99bf40"
                                  : "",
                              padding: "10px 10px 10px",
                              display: "flex",
                              marginBottom: "10px",
                            }}
                          >
                            <Tooltip
                              title={langConstant.COUNT_OF_MATCHES_INDENTIFIED}
                            >
                              <span
                                className={classes.countNum}
                                onClick={() => {
                                  if (type === "base") {
                                    setSelectedBaseHeader({
                                      header: value["NAME"],
                                      text: content.trim(),
                                      type: "H",
                                    });
                                    if (
                                      selectedHeader.header != value["NAME"] ||
                                      selectedHeader.type != "H"
                                    ) {
                                      setSelectedHeader({
                                        header: value["NAME"],
                                        type: "H",
                                      });
                                    }
                                  }
                                }}
                              >
                                {comparisonCountByHeader[content]
                                  ? comparisonCountByHeader[content].length
                                  : 0}
                              </span>
                            </Tooltip>
                            <Grid
                              dangerouslySetInnerHTML={{
                                __html: replaceContent,
                              }}
                              className={classes.titalSpace}
                              onClick={() => {
                                if (type === "base") {
                                  setSelectedBaseHeader({
                                    header: value["NAME"],
                                    text: content.trim(),
                                    type: "H",
                                  });
                                  if (
                                    selectedHeader.header != value["NAME"] ||
                                    selectedHeader.type != "H"
                                  ) {
                                    setSelectedHeader({
                                      header: value["NAME"],
                                      type: "H",
                                    });
                                  }
                                }
                              }}
                            ></Grid>
                            {/* {contentIndex !== bodyContent.length - 1 && <Divider />} */}
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Typography className={classes.headerFont}>
                {value["NAME"]}
              </Typography>
            )}
          </>
        );
      },
    },
  ];

  const checkSplittingAvailibility = async () => {
    if (chatBotAvailibility.isChatBoxAvailable) {
      setIsChatBoxAvailable(true);
    } else {
      setDocumentSplitterPopUp(true);
    }
  };

  const handleChatBoxClose = () => setIsChatBoxAvailable(false);

  const updateChatBotSplittingStatus = () => {
    setChatBotAvailibility((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], isChatBoxAvailable: true },
    }));
  };

  return (
    <Grid>
      <Grid
        container
        style={{ padding: type === "base" ? "14.5px 0px" : "8px 0px" }}
      >
        <Grid
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          item
          md={6}
        >
          <Typography
            style={{ display: "inline" }}
            className={classes.headerFont}
          >
            {type === "base"
              ? langConstant.BASE_DOCUMENT
              : langConstant.TARGET_DOCUMENT}
          </Typography>
          <span
            className={classes.actionWrap}
            style={{
              backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              downloadFile(
                policyFolderUrl,
                policyName,
                docStorageType,
                docStorageIP,
                docStorageURL
              );
            }}
          >
            <Tooltip
              title={
                type === "base"
                  ? langConstant.DOWNLOAD_BASE_DOCUMENT
                  : langConstant.DOWNLOAD_TARGET_DOCUMENT
              }
            >
              <DownloadIcon className={classes.actionButton} />
            </Tooltip>
          </span>
          {allowedNavItems &&
            allowedNavItems.length > 0 &&
            allowedNavItems.indexOf("AI_ASSIST_PM_POLICY") > -1 && (
              <>
                <Tooltip title={langConstant.CHAT}>
                  <Box
                    className={classes.actionWrap}
                    style={{
                      backgroundColor:
                        themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => checkSplittingAvailibility()}
                  >
                    <ChatIcon className={classes.actionButton} />
                  </Box>
                </Tooltip>
                <Tooltip title={langConstant.CHUNKING_STATUS}>
                  <CircleIcon
                    style={{
                      color: chatBotAvailibility.isChatBoxAvailable
                        ? "green"
                        : "red",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
              </>
            )}
        </Grid>
        {type === "target" && (
          <Grid item md={6} style={{ textAlign: "right" }}>
            {expandedList.length > 1 ? (
              <Button
                color="primary"
                onClick={() => expandCollapseAll("collapse")}
                style={{ fontWeight: "bold" }}
              >
                <UnfoldLessIcon style={{ marginRight: "5px" }} />{" "}
                {langConstant.COLLAPSE_ALL}
              </Button>
            ) : (
              <Button
                onClick={() => expandCollapseAll("expand")}
                style={{ fontWeight: "bold" }}
              >
                <UnfoldMoreIcon style={{ marginRight: "5px" }} />{" "}
                {langConstant.EXPAND_ALL}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Box className={classes.BoxPages}>
        {sentences && sentences.length ? (
          <TableContainer>
            <Table>
              <TableBody>
                {sentences.map((item, index) => {
                  return (
                    <TableRow style={{ position: "relative" }} key={index}>
                      {columnData.map((column, columnIndex) => {
                        return (
                          <TableCell
                            key={columnIndex}
                            style={{
                              borderBottom:
                                type == "base" ? "none" : "1px solid #e0e0e0",
                            }}
                          >
                            {column.format
                              ? column.format(item)
                              : item[column["key"]]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid>
            <Typography className={classes.NoContent}>
              {langConstant.NO_CONTENT_EXTRACTED}
            </Typography>
          </Grid>
        )}
      </Box>
      {pageCount > 1 && (
        <Grid className={classNames(classes.textRight, classes.marginTop15px)}>
          <AppPagination
            colSpan={3}
            count={pageCount}
            rowsPerPage={rowsPerPage}
            page={currentPolicyPageNo}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      )}
      {documentSplitterPopUp && (
        <DocumentSplitter
          open={documentSplitterPopUp}
          setOpen={setDocumentSplitterPopUp}
          selectedPolicy={{ POLICY_ID: policyID, POLICY_FILE_NAME: policyName }}
          updateChatBotSplittingStatus={updateChatBotSplittingStatus}
        />
      )}
      {isChatBoxAvailable && (
        <ChatBox
          handleClose={handleChatBoxClose}
          isChatBoxAvailable={isChatBoxAvailable}
          selectedPolicy={{ POLICY_ID: policyID, POLICY_FILE_NAME: policyName }}
          entitlement="AI_ASSIST_PM_POLICY"
        />
      )}
    </Grid>
  );
};

FilterSentenceComponent.propTypes = {
  sentences: PropTypes.array.isRequired,
  vectorsFilter: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setComparedSentencesView: PropTypes.func,
  setSelectedBaseHeader: PropTypes.func,
  policyFolderUrl: PropTypes.string.isRequired,
  policyName: PropTypes.string.isRequired,
  selectedBaseHeader: PropTypes.object,
  setSelectedHeader: PropTypes.func,
  selectedHeader: PropTypes.object,
  setMatchedContent: PropTypes.array,
  policyID: PropTypes.number,
  chatBotAvailibility: PropTypes.object,
  setChatBotAvailibility: PropTypes.func,
  // tokenizedSentences: PropTypes.array,
};

export default FilterSentenceComponent;
